import React, { Component } from "react";
import { routes } from "../../constants/routes";
import ProtectedRoute from "../ProtectedRoute/protected-route";
import { Switch } from "react-router";

export default class Body extends Component {
  render() {
    return (
      <div>
        <Switch>

          {routes.map((current) => {
            return (
              <ProtectedRoute
                {...this.props}
                path={current.path}
                component={current.component}
                key={current.path}
                rights={current.rights}
              />
            );
          })}
        </Switch>
      </div>
    );
  }
}
