import axios from "axios";
import { UNITY_LANGUAGE } from "../constants/action-types";

export const getAllUnityLanguagedValues= () => {
  return async (dispatch) => {
    dispatch({ type: UNITY_LANGUAGE.GET_ALL_LANGUAGED_VALUES_LOADING });
    await axios
      .get("/unity-language", {})
      .then((response) => {
        dispatch({
          type: UNITY_LANGUAGE.GET_ALL_LANGUAGED_VALUES_LOADED,
          payload: response.data.languagedValues,
        });
      })
      .catch((err) => {
        dispatch({
          type: UNITY_LANGUAGE.GET_ALL_LANGUAGED_VALUES_ERROR,
          payload: {
            message: err.response.data.error.message, 
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const saveUnityLanguagedValues = (saveObj) => {
  return async (dispatch) => {
    dispatch({ type: UNITY_LANGUAGE.CHANGE_LANGUAGED_VALUES_SAVING });
    await axios
      .put("/unity-language/change-values-by-id", saveObj)
      .then((response) => {
        dispatch({
          type: UNITY_LANGUAGE.CHANGE_LANGUAGED_VALUES_SAVED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: UNITY_LANGUAGE.CHANGE_LANGUAGED_VALUES_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
