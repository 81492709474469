import axios from "axios";
import { LOGIN_ACTIONS } from "../constants/action-types";

export const login = (email, password) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_ACTIONS.TOKEN_LOADING });

    await axios
      .post(process.env.REACT_APP_API_URL + "/auth/login", {
        email,
        password,
      })
      .then((response) => {
        const tokenToSave = "Bearer " + response.data.accessToken;
        // console.log(response.data);
        if (
          response.data.user &&
          response.data.user.right &&
          response.data.user.right.id &&
          response.data.user.right.id === 1
        ) {
          dispatch({
            type: LOGIN_ACTIONS.TOKEN_LOADED,
            payload: { token: tokenToSave, user: response.data.user },
          });
        } else {
          dispatch({
            type: LOGIN_ACTIONS.TOKEN_ERROR,
            payload: {
              message: "Forbidden",
              code: 30,
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_ERROR,
          payload: {
            message: err.response.data.message,
            code: err.response.data.code,
          },
        });
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: LOGIN_ACTIONS.LOGOUT });
  };
};
