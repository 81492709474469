import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Row, Space, Table, Radio, Drawer, Spin } from "antd";
import { withTranslation } from "react-i18next";
import {
  activateDigitalExpoThread,
  cloneExpoThread,
  createDigitalExpo,
  getAllDigitalExpo,
  setExpoId,
} from "../../actions/expo";
import { getAllLanguage } from "../../actions/language";
import Maps from "../maps/maps";
import * as _ from "lodash";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLanguage: () => dispatch(getAllLanguage()),
    getAllDigitalExpo: () => dispatch(getAllDigitalExpo()),
    cloneExpo: (expoId) => dispatch(cloneExpoThread(expoId)),
    activateDigitalExpo: (expoId) =>
      dispatch(activateDigitalExpoThread(expoId)),
    setExpoId: (expoId) => dispatch(setExpoId(expoId)),
    createDigitalExpo: () => dispatch(createDigitalExpo()),
  };
};

class ConnectedDigitalExpo extends Component {
  state = {
    loadWhenCloning: false,
    drawerOpen: false,
    selectedExpoId: null,
    isRealExpo: false,
    intervalId: null,
    expos: [],
    loading: false,
  };
  componentDidMount = async () => {
    this.setState({ loading: true });
    await this.props.getAllLanguage();
    await this.props.getAllDigitalExpo();
    this.setState({ expos: this.props.expos }, () => {
      this.setState({ loading: false });
    });
    const i = setInterval(async () => {
      await this.props.getAllDigitalExpo();
      if (
        this.props.expos &&
        this.props.expos[0] &&
        !this.isArrayEqual(this.props.expos, this.state.expos)
      ) {
        this.setExpoData();
      }
    }, 3000);
    this.setState({ intervalId: i });
  };
  componentWillUnmount = () => {
    clearInterval(this.state.intervalId);
  };
  setExpoData = () => {
    if (this.props.expos) {
      this.setState({ expos: this.props.expos });
    }
  };
  isArrayEqual = function (x, y) {
    return _(x).differenceWith(y, _.isEqual).isEmpty();
  };
  cloneExpo = async (row) => {
    this.setState({ loadWhenCloning: true });
    await this.props.cloneExpo(row.id);
    await this.props.getAllDigitalExpo();
    if (
      this.props.expos &&
      this.props.expos[0] &&
      !this.isArrayEqual(this.props.expos, this.state.expos)
    ) {
      this.setExpoData();
    }
    if (this.props.cloneSuccess === true) {
      await this.props.getAllDigitalExpo();
    }
    this.setState({ loadWhenCloning: false });
  };

  activateExpo = async (row) => {
    this.setState({ loadWhenCloning: true });
    await this.props.activateDigitalExpo(row.id);
    await this.props.getAllDigitalExpo();
    if (
      this.props.expos &&
      this.props.expos[0] &&
      !this.isArrayEqual(this.props.expos, this.state.expos)
    ) {
      this.setExpoData();
    }
    if (this.props.activateSuccess === true) {
      await this.props.getAllDigitalExpo();
    }
    this.setState({ loadWhenCloning: false });
  };

  closeDrawer = () => {
    this.setState({ drawerOpen: false });
  };
  editExpo = async (row) => {
    this.props.setExpoId(row.id);
    this.setState({
      drawerOpen: true,
      selectedExpoId: row.id,
    });
  };
  addNewExpo = async () => {
    await this.props.createDigitalExpo();
    await this.props.getAllDigitalExpo();
  };

  render() {
    const columns = [
      {
        title: this.props.t("TABLE_EXPO_NAME"),
        dataIndex: "name",
        key: "name",
        render: (text) => <div>{text}</div>,
      },
      {
        title: this.props.t("TABLE_EXPO_IS_ACTIVE"),
        dataIndex: "isActive",
        align: "center",
        key: "isActive",
        render: (text, row) => (
          <Space size="middle">
            <Radio checked={row.isActive}></Radio>
            {/* <Switch disabled={true} checked={row.isActive} /> */}
          </Space>
        ),
      },
      {
        title: this.props.t("TABLE_EXPO_EDIT"),
        render: (text, row) =>
          !row.isActive ? (
            <Button
              disabled={!row.isEditable || row.savingActivationStatus}
              onClick={() => this.editExpo(row)}
            >
              {this.props.t("TABLE_EXPO_EDIT_BUTTON")}
            </Button>
          ) : null,
      },
      {
        title: this.props.t("TABLE_EXPO_ACTIVATE"),
        render: (text, row) => (
          <Button
            loading={row.savingActivationStatus}
            disabled={!row.isEditable && row.isActive}
            onClick={() => this.activateExpo(row)}
          >
            {this.props.t("TABLE_EXPO_ACTIVATE_BUTTON")}
          </Button>
        ),
      },
      {
        title: this.props.t("TABLE_EXPO_DUPLICATION"),
        render: (text, row) =>
          this.state.expos.length < 2 && row.isActive ? (
            <Button
              loading={row.savingDuplicationStatus}
              onClick={() => this.cloneExpo(row)}
            >
              {this.props.t("TABLE_EXPO_DUPLICATION_BUTTON")}
            </Button>
          ) : null,
      },
      // {
      //   title: "Loading",
      //   render: (text, row) => (row.saving ? <Spin /> : null),
      // },
    ];
    return (
      <React.Fragment>
        {this.props.expos && this.state.expos && this.state.expos[0] ? (
          <Row gutter={24}>
            <Col span={24}>
              <Table
                loading={this.state.loadWhenCloning}
                size="middle"
                pagination={{
                  position: ["bottomCenter"],
                  pageSize: this.state.pageSize,
                  current: this.state.current,
                }}
                onChange={this.handleChangeTable}
                onShowSizeChange
                rowKey="id"
                //   rowSelection={rowSelection}
                columns={columns}
                dataSource={this.state.expos}
                scroll={{ y: "60vh" }}
              ></Table>
            </Col>
          </Row>
        ) : (
          <Row gutter={[24, 24]} align="middle" justify="center">
            {this.state.loading === true ? (
              <Spin />
            ) : (
              <Button onClick={this.addNewExpo} type="primary">
                {this.props.t("ADD_NEW_REAL_EXPO")}
              </Button>
            )}
          </Row>
        )}
        <Drawer
          title={this.props.t("MAP_DRAWER_TITLE")}
          width="100%"
          onClose={this.closeDrawer}
          visible={this.state.drawerOpen}
          destroyOnClose={true}
        >
          {/* <SelectedDigitalExpo expoId={this.state.selectedExpoId}></SelectedDigitalExpo> */}
          <Maps
            expoId={this.state.selectedExpoId}
            isRealExpo={this.state.isRealExpo}
          ></Maps>
        </Drawer>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  expos: state.expoReducer.expos,
  cloneSuccess: state.expoReducer.cloneSuccess,
  activateSuccess: state.expoReducer.activateSuccess,
});
const DigitalExpo = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedDigitalExpo)
);

export default DigitalExpo;
