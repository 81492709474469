import axios from "axios";
import { LANGUAGE } from "../constants/action-types";

export const getAllLanguage = () => {
  return async (dispatch) => {
    dispatch({ type: LANGUAGE.GET_ALL_LANGUAGE_LOADING });
    await axios
      .get("/language", {})
      .then((response) => {
        dispatch({
          type: LANGUAGE.GET_ALL_LANGUAGE_LOADED,
          payload: response.data.languages,
        });
      })
      .catch((err) => {
        dispatch({
          type: LANGUAGE.GET_ALL_LANGUAGE_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
