import axios from "axios";
import { MAP } from "../constants/action-types";

export const getPoisByMapId = (id) => {
  return async (dispatch) => {
    dispatch({ type: MAP.GET_POIS_BY_MAP_ID_LOADING });
    await axios
      .get("/map/" +id, {})
      .then((response) => {
        dispatch({
          type: MAP.GET_POIS_BY_MAP_ID_LOADED,
          payload: response.data.pois,
        });
      })
      .catch((err) => {
        dispatch({
          type: MAP.GET_POIS_BY_MAP_ID_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const getMapPicture = (id) => {
  return async (dispatch) => {
    dispatch({ type: MAP.GET_BASE_PICTURE_BY_MAP_ID_LOADING });
    await axios
      .get("/map/" + id + "/picture", {})
      .then((response) => {
        dispatch({
          type: MAP.GET_BASE_PICTURE_BY_MAP_ID_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: MAP.GET_BASE_PICTURE_BY_MAP_ID_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
