import axios from "axios";
import { CARD } from "../constants/action-types";

export const getCardDataById = (cardId, languageId) => {
  return async (dispatch) => {
    dispatch({ type: CARD.GET_CARD_DATA_LOADING });
    await axios
      .get("/card/" + cardId + "/" + languageId, {})
      .then((response) => {
        dispatch({
          type: CARD.GET_CARD_DATA_LOADED,
          payload: response.data.card,
        });
      })
      .catch((err) => {
        dispatch({
          type: CARD.GET_CARD_DATA_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const createCard = (poiId) => {
  return async (dispatch) => {
    dispatch({ type: CARD.CREATE_CARD_SAVING });
    await axios
      .post("/card/" + poiId, {})
      .then((response) => {
        dispatch({
          type: CARD.CREATE_CARD_SAVED,
          payload: response.data.card,
        });
      })
      .catch((err) => {
        dispatch({
          type: CARD.CREATE_CARD_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const saveCard = (cardObj) => {
  return async (dispatch) => {
    dispatch({ type: CARD.EDIT_CARD_SAVING });
    await axios
      .put("/card/", cardObj)
      .then((response) => {
        dispatch({
          type: CARD.EDIT_CARD_SAVED,
          payload: response.data.card,
        });
      })
      .catch((err) => {
        dispatch({
          type: CARD.EDIT_CARD_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const deleteCard = (cardId) => {
  return async (dispatch) => {
    dispatch({ type: CARD.DELETE_CARD_SAVING });
    await axios
      .delete("/card/" + cardId)
      .then((response) => {
        dispatch({
          type: CARD.DELETE_CARD_SAVED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: CARD.DELETE_CARD_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const changeCardValues = (cardData) => {
  return async (dispatch) => {
    dispatch({ type: CARD.UPDATE_CARD_DATA_SAVING });
    await axios
      .put("/card/update", cardData)
      .then((response) => {
        dispatch({
          type: CARD.UPDATE_CARD_DATA_SAVED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: CARD.UPDATE_CARD_DATA_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
}
