import axios from 'axios';

export default class HttpService {

  static headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  };

  static baseURL = process.env.REACT_APP_API_URL;

  static setAxiosDefaults() {
    axios.defaults.headers = this.headers;
    axios.defaults.baseURL = this.baseURL;
  }

  static setTokenHeader(token) {
    axios.defaults.headers.common = { 'Authorization': `${token}` }
  }

  static clearTokenHeader() {
    axios.defaults.headers.common = { 'Authorization': `` }
  }
}
