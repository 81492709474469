import { POI } from "../constants/action-types";

const initialState = {
  poi: null,
  loadedPoi: null,
  message: {},
  code: null,
  deleteStatus: null,
  title: ""
};

const poiReducer = (state = initialState, action) => {
  switch (action.type) {
    case POI.CREATE_POI_SAVING:
      return Object.assign({}, state, {
        poi: {},
        message: {},
        status: "saving",
        code: null,
      });
    case POI.CREATE_POI_SAVED:
      return Object.assign({}, state, {
        poi: action.payload,
        status: "saved",
        message: {},
        code: null,
      });
    case POI.CREATE_POI_ERROR:
      return Object.assign({}, state, {
        poi: null,
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    case POI.GET_POI_LANGUAGED_TITLE_LOADING:
      return Object.assign({}, state, {
        title: "",
        message: {},
        status: "loading",
        code: null,
      });
    case POI.GET_POI_LANGUAGED_TITLE_LOADED:
      return Object.assign({}, state, {
        title: action.payload,
        status: "laoded",
        message: {},
        code: null,
      });
    case POI.GET_POI_LANGUAGED_TITLE_ERROR:
      return Object.assign({}, state, {
        title: "",
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    case POI.GET_POI_DATA_LOADING:
      return Object.assign({}, state, {
        loadedPoi: {},
        message: {},
        status: "loading",
        code: null,
      });
    case POI.GET_POI_DATA_LOADED:
      return Object.assign({}, state, {
        loadedPoi: action.payload,
        status: "loaded",
        message: {},
        code: null,
      });
    case POI.GET_POI_DATA_ERROR:
      return Object.assign({}, state, {
        loadedPoi: null,
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    case POI.DELETE_POI_SAVING:
      return Object.assign({}, state, {
        message: {},
        deleteStatus: "deleting",
        code: null,
      });
    case POI.DELETE_POI_SAVED:
      return Object.assign({}, state, {
        deleteStatus: "deleted",
        message: {},
        code: null,
      });
    case POI.DELETE_POI_ERROR:
      return Object.assign({}, state, {
        deleteStatus: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    case POI.DELETE_MAP_FROM_POI_SAVING:
      return Object.assign({}, state, {
        message: {},
        deleteStatus: "deleting",
        code: null,
      });
    case POI.DELETE_MAP_FROM_POI_SAVED:
      return Object.assign({}, state, {
        deleteStatus: "deleted",
        message: {},
        code: null,
      });
    case POI.DELETE_MAP_FROM_POI_ERROR:
      return Object.assign({}, state, {
        deleteStatus: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    default:
      return state;
  }
};

export default poiReducer;
