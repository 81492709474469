import {  UNITY_LANGUAGE } from "../constants/action-types";

const initialState = {
  lanugedValues: null,
  message: {},
  status: {},
  changeStatus: {},
  code: null,
};

const unityLanguageReducer = (state = initialState, action) => {
  switch (action.type) {
    case UNITY_LANGUAGE.GET_ALL_LANGUAGED_VALUES_LOADING:
      return Object.assign({}, state, {
        lanugedValues: {},
        message: {},
        status: "saving",
        code: null,
      });
    case UNITY_LANGUAGE.GET_ALL_LANGUAGED_VALUES_LOADED:
      return Object.assign({}, state, {
        lanugedValues: action.payload,
        status: "saved",
        message: {},
        code: null,
      });
    case UNITY_LANGUAGE.GET_ALL_LANGUAGED_VALUES_ERROR:
      return Object.assign({}, state, {
        lanugedValues: null,
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    case UNITY_LANGUAGE.CHANGE_LANGUAGED_VALUES_SAVING:
      return Object.assign({}, state, {
        message: {},
        changeStatus: "saving",
        code: null,
      });
    case UNITY_LANGUAGE.CHANGE_LANGUAGED_VALUES_SAVED:
      return Object.assign({}, state, {
        changeStatus: "saved",
        message: {},
        code: null,
      });
    case UNITY_LANGUAGE.CHANGE_LANGUAGED_VALUES_ERROR:
      return Object.assign({}, state, {
        changeStatus: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    default:
      return state;
  }
};

export default unityLanguageReducer;
