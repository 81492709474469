import React, { Component } from "react";
import { Input, Button, Form, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./login.scss";
import { login } from "../../actions/login";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import HttpService from "../../http";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";


const mapDispatch = (dispatch) => {
  return {
    login: (email, password) => dispatch(login(email, password)),
  };
};
class ConnectedLogin extends Component {
  state = {
    password: null,
    email: null,
    loginSuccesful: false,
  };
  componentDidMount = () => {
    HttpService.setAxiosDefaults();
    console.log(process.env.REACT_APP_FACEBOOK_APP_ID);
  };
  handlePasswordChange = (event) => {
    if (event.target.value !== undefined) {
      this.setState({ password: event.target.value });
    }
  };
  handleEmailChange = (event) => {
    if (event.target.value !== undefined) {
      this.setState({ email: event.target.value });
    }
  };
  clickOnLoginButton = async () => {
    await this.props.login(this.state.email, this.state.password);
    if (this.props.status !== "loaded") {
      console.log(this.props.code);
      if (this.props.code === 1002) {
        notification["error"]({
          message: this.props.t("LOGIN_ERROR_NOTIFICATION_TITLE"),
          description: this.props.message,
        });
      } else {
        notification["error"]({
          message: this.props.t("LOGIN_ERROR_NOTIFICATION_TITLE"),
          description: this.props.t("LOGIN_ERROR_NOTIFICATION_MESSAGE"),
        });
      }
    } else {
      HttpService.setAxiosDefaults();
      HttpService.setTokenHeader(this.props.token);
      const cookies = new Cookies();
      cookies.set("token", this.props.token, { path: "/" });
      cookies.set("userId", this.props.loggedInUser.id, { path: "/" });
      this.setState({ loginSuccesful: true });
    }
  };
  render() {
    return (
      <div className="login-page-container">
        <div>
          <Form
            name="normal_login"
            className="regoist-form loginform"
            initialValues={{ remember: true }}
            //onFinish={onFinish}
          >
            <h1>{this.props.t("LOGIN_TITLE")}</h1>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: this.props.t("LOGIN_USERNAME_MISSING"),
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
                onChange={this.handleEmailChange}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: this.props.t("LOGIN_USERPASSWORD_MISSING"),
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                onChange={this.handlePasswordChange}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                onClick={this.clickOnLoginButton}
              >
                {this.props.t("LOGIN_BUTTON")}
              </Button>
            </Form.Item>
            {this.state.loginSuccesful && (
              <Redirect Form="**" to="/one-with-nature/real-expo" />
            )}
          </Form>
        </div>
      </div>
    );
  }
}
const mapState = (state) => {
  return {
    token: state.loginReducer.token,
    message: state.loginReducer.message,
    code: state.loginReducer.code,
    status: state.loginReducer.status,
    loggedInUser: state.loginReducer.user,
  };
};

const Login = withTranslation()(connect(mapState, mapDispatch)(ConnectedLogin));
export default Login;
