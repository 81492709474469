import DigitalExpo from "../components/digital-expo/digital-expo";
import Maps from "../components/maps/maps";
import RealExpo from "../components/real-expo/real-expo";
import Unity from "../components/unity/untiy";

export const routes = [
  {
    path: "/one-with-nature/real-expo",
    component: RealExpo,
    rights: [1, 2],
  },
  {
    path: "/one-with-nature/digital-expo",
    component: DigitalExpo,
    rights: [1, 2],
  },
  {
    path: "/one-with-nature/maps",
    component: Maps,
    rights: [1, 2],
  },
  {
    path: "/one-with-nature/unity",
    component: Unity,
    rights: [1, 2],
  },
];
