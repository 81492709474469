import axios from "axios";
import { EXPO } from "../constants/action-types";

export const getExpoData = (data) => {
  return async (dispatch) => {
    dispatch({ type: EXPO.GET_EXPO_DATA_LOADING });
    await axios
      .post("/expo", data)
      .then((response) => {
        dispatch({
          type: EXPO.GET_EXPO_DATA_LOADED,
          payload: response.data.expo,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXPO.GET_EXPO_DATA_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const getDigitalExpoData = (data) => {
  return async (dispatch) => {
    dispatch({ type: EXPO.GET_EXPO_DATA_LOADING });
    await axios
      .post("/expo", data)
      .then((response) => {
        dispatch({
          type: EXPO.GET_EXPO_DATA_LOADED,
          payload: response.data.expo,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXPO.GET_EXPO_DATA_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const getExpoMapData = (data) => {
  return async (dispatch) => {
    dispatch({ type: EXPO.GET_EXPO_MAP_DATA_LOADING });
    await axios
      .post("/expo/expo-map-to-cms", data)
      .then((response) => {
        dispatch({
          type: EXPO.GET_EXPO_MAP_DATA_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXPO.GET_EXPO_MAP_DATA_ERROR,
          payload: {
            message: err.response.data.message,
            code: err.response.data.code,
          },
        });
      });
  };
};
export const getAllExpo = () => {
  return async (dispatch) => {
    dispatch({ type: EXPO.GET_ALL_EXPO_LOADING });
    await axios
      .get("/expo/all-real-expo", {})
      .then((response) => {
        dispatch({
          type: EXPO.GET_ALL_EXPO_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXPO.GET_ALL_EXPO_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const getAllDigitalExpo = () => {
  return async (dispatch) => {
    dispatch({ type: EXPO.GET_ALL_EXPO_LOADING });
    await axios
      .get("/expo/all-digital-expo", {})
      .then((response) => {
        dispatch({
          type: EXPO.GET_ALL_EXPO_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXPO.GET_ALL_EXPO_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const cloneExpo = (expoId) => {
  return async (dispatch) => {
    dispatch({ type: EXPO.CLONE_EXPO_LOADING });
    await axios
      .post("/expo/clone/" + expoId)
      .then((response) => {
        dispatch({
          type: EXPO.CLONE_EXPO_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXPO.CLONE_EXPO_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const cloneExpoThread = (expoId) => {
  return async (dispatch) => {
    dispatch({ type: EXPO.CLONE_EXPO_LOADING });
    await axios
      .post("/expo/clone-thread/" + expoId)
      .then((response) => {
        dispatch({
          type: EXPO.CLONE_EXPO_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXPO.CLONE_EXPO_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const activateRealExpo = (expoId) => {
  return async (dispatch) => {
    dispatch({ type: EXPO.ACTIVATE_EXPO_LOADING });
    await axios
      .post("/expo/activate-real-expo/" + expoId)
      .then((response) => {
        dispatch({
          type: EXPO.ACTIVATE_EXPO_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXPO.ACTIVATE_EXPO_ERRPR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const activateDigitalExpo = (expoId) => {
  return async (dispatch) => {
    dispatch({ type: EXPO.ACTIVATE_EXPO_LOADING });
    await axios
      .post("/expo/activate-digital-expo/" + expoId)
      .then((response) => {
        dispatch({
          type: EXPO.ACTIVATE_EXPO_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXPO.ACTIVATE_EXPO_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const activateDigitalExpoThread = (expoId) => {
  return async (dispatch) => {
    dispatch({ type: EXPO.ACTIVATE_EXPO_LOADING });
    await axios
      .post("/expo/activate-digital-expo-thread/" + expoId)
      .then((response) => {
        dispatch({
          type: EXPO.ACTIVATE_EXPO_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXPO.ACTIVATE_EXPO_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const activateRealExpoThread = (expoId) => {
  return async (dispatch) => {
    dispatch({ type: EXPO.ACTIVATE_EXPO_LOADING });
    await axios
      .post("/expo/activate-real-expo-thread/" + expoId)
      .then((response) => {
        dispatch({
          type: EXPO.ACTIVATE_EXPO_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXPO.ACTIVATE_EXPO_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const addNewBaseMapToExpo = (expoId) => {
  return async (dispatch) => {
    dispatch({ type: EXPO.ADD_NEW_BASE_MAP_TO_EXPO_LOADING });
    await axios
      .put("/expo/add-new-base-map/" + expoId)
      .then((response) => {
        dispatch({
          type: EXPO.ADD_NEW_BASE_MAP_TO_EXPO_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXPO.ADD_NEW_BASE_MAP_TO_EXPO_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const setExpoId = (expoId) => {
  return async (dispatch) => {
    dispatch({ type: EXPO.EXPO_DATA_ID, payload: expoId });
  };
};
export const createRealExpo = () => {
  return async (dispatch) => {
    dispatch({ type: EXPO.CREATE_REAL_EXPO_LOADING });
    await axios
      .put("/expo/real-expo")
      .then((response) => {
        dispatch({
          type: EXPO.CREATE_REAL_EXPO_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXPO.CREATE_REAL_EXPO_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const createDigitalExpo = () => {
  return async (dispatch) => {
    dispatch({ type: EXPO.CREATE_REAL_EXPO_LOADING });
    await axios
      .put("/expo/digital-expo")
      .then((response) => {
        dispatch({
          type: EXPO.CREATE_REAL_EXPO_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXPO.CREATE_REAL_EXPO_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
