import axios from "axios";
import { POI } from "../constants/action-types";

export const getPoiNameByPoiIdAndLanguageId= (poiId, languageId) => {
  return async (dispatch) => {
    dispatch({ type: POI.GET_POI_LANGUAGED_TITLE_LOADING });
    await axios
      .get("/poi/" + poiId + "/" + languageId, {})
      .then((response) => {
        dispatch({
          type: POI.GET_POI_LANGUAGED_TITLE_LOADED,
          payload: response.data.poiName,
        });
      })
      .catch((err) => {
        dispatch({
          type: POI.GET_POI_LANGUAGED_TITLE_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const getPoiDataById = (poiId) => {
  return async (dispatch) => {
    dispatch({ type: POI.GET_POI_DATA_LOADING });
    await axios
      .get("/poi/" + poiId, {})
      .then((response) => {
        dispatch({
          type: POI.GET_POI_DATA_LOADED,
          payload: response.data.poi,
        });
      })
      .catch((err) => {
        dispatch({
          type: POI.GET_POI_DATA_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const createPoi = (mapId) => {
  return async (dispatch) => {
    dispatch({ type: POI.CREATE_POI_SAVING });
    await axios
      .put("/poi/" + mapId, {})
      .then((response) => {
        dispatch({
          type: POI.CREATE_POI_SAVED,
          payload: response.data.poi,
        });
      })
      .catch((err) => {
        dispatch({
          type: POI.CREATE_POI_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};

export const savePoiPosition = (poiDTO) => {
  return async (dispatch) => {
    dispatch({ type: POI.POI_POSITION_CHANGE_SAVING });
    await axios
      .put("/poi/change/position", poiDTO)
      .then((response) => {
        dispatch({
          type: POI.POI_POSITION_CHANGE_SAVED,
          payload: response.data.poi,
        });
      })
      .catch((err) => {
        dispatch({
          type: POI.POI_POSITION_CHANGE_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
export const addMapToPoi = (poiId) => {
  return async (dispatch) => {
    dispatch({ type: POI.ADD_MAP_TO_POI_SAVING });
    await axios
      .put("/poi/add-map-to-poi/" + poiId, {})
      .then((response) => {
        dispatch({
          type: POI.ADD_MAP_TO_POI_SAVED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: POI.ADD_MAP_TO_POI_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};

export const deletePoi = (poiId) => {
  return async (dispatch) => {
    dispatch({ type: POI.DELETE_POI_SAVING });
    await axios
      .delete("/poi/" + poiId)
      .then((response) => {
        dispatch({
          type: POI.DELETE_POI_SAVED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: POI.DELETE_POI_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};

export const savePoiChangeName = (poi) => {
   return async (dispatch) => {
     dispatch({ type: POI.POI_NAME_CHANGE_SAVING });
     await axios
       .put("/poi/change/name", poi)
       .then((response) => {
         dispatch({
           type: POI.POI_NAME_CHANGE_SAVED,
           payload: response.data.poi,
         });
       })
       .catch((err) => {
         dispatch({
           type: POI.POI_NAME_CHANGE_ERROR,
           payload: {
             message: err.response.data.error.message,
             code: err.response.data.error.code,
           },
         });
       });
   };
}

export const deleteMapFromPoi = (poiId) => {
  return async (dispatch) => {
    dispatch({ type: POI.DELETE_MAP_FROM_POI_SAVING });
    await axios
      .delete("/poi/delete-map-from-poi/" + poiId)
      .then((response) => {
        dispatch({
          type: POI.DELETE_MAP_FROM_POI_SAVED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: POI.DELETE_MAP_FROM_POI_ERROR,
          payload: {
            message: err.response.data.error.message,
            code: err.response.data.error.code,
          },
        });
      });
  };
};
