import { SPINNING } from "../constants/action-types";

const initialState = {
  spinning: false,
};

const spinningReducer = (state = initialState, action) => {
  switch (action.type) {
    case SPINNING.TRUE:
      return Object.assign({}, state, {
        spinning: true,
      });
    case SPINNING.FALSE:
      return Object.assign({}, state, {
        spinning: false,
      });
    default:
      return state;
  }
};

export default spinningReducer;
