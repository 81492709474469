import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Input,
  message,
  notification,
  Row,
  Upload,
} from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { changeCardValues, getCardDataById } from "../../actions/card";
import ReactPlayer from "react-player";
import { setSpinning } from "../../actions/spinning";
import { Pannellum } from "pannellum-react";

const { TextArea } = Input;
const mapDispatchToProps = (dispatch) => {
  return {
    getCardDataById: (cardId, languageId) =>
      dispatch(getCardDataById(cardId, languageId)),
    changeCardValues: (cardData) => dispatch(changeCardValues(cardData)),
    setSpinning: (status) => dispatch(setSpinning(status)),
  };
};

class ConnectedCardData extends Component {
  state = {
    cardData: {},
    title: "",
    basePictureURL: "",
    panoramaPictureURL: "",
    titlePictureURL: "",
    description: "",
    saveButtonActive: false,
    panoramaExtension: "png",
    uploadingStatus: false,
  };

  componentDidMount = async () => {
    await this.props.getCardDataById(this.props.cardId, this.props.languageId);
    this.setState({
      title:
        this.props.card &&
        this.props.card.translateTitle &&
        this.props.card.translateTitle[0]
          ? this.props.card.translateTitle[0].value
          : "",
      description:
        this.props.card &&
        this.props.card.translateDescription &&
        this.props.card.translateDescription[0]
          ? this.props.card.translateDescription[0].value
          : "",
    });
    if (this.props.card.basePicture && this.props.card.basePicture[0]) {
      this.setState({
        basePictureURL: this.props.card.basePicture[0].fileSavedURL,
      });
    }
    if (this.props.card.panoramaPicture && this.props.card.panoramaPicture[0]) {
      const fileName =
        this.props.card.panoramaPicture[0].fileSavedURL.split("=");
      const ext = fileName[1].split(".");
      this.setState({
        panoramaPictureURL: this.props.card.panoramaPicture[0].fileSavedURL,
        panoramaExtension: ext[1],
      });
    }
    if (this.props.card.titlePicture && this.props.card.titlePicture[0]) {
      this.setState({
        titlePictureURL: this.props.card.titlePicture[0].fileSavedURL,
      });
    }
  };

  changeTitle = (event) => {
    const actValue = event.target.value;
    this.setState({ title: actValue }, () => {
      this.checkAndSaveButtonSetActive();
    });
  };

  changeDescription = (event) => {
    const actValue = event.target.value;
    this.setState({ description: actValue }, () => {
      this.checkAndSaveButtonSetActive();
    });
  };
  //BASE PICTURE UPLOAD
  onChangeBasePicture = async (event) => {
    if (event.file.status === "uploading") {
      this.props.setSpinning(true);
    }
    if (event.file.status === "done") {
      this.props.setSpinning(false);
      await this.props.getCardDataById(
        this.props.cardId,
        this.props.languageId
      );
      if (this.props.card.basePicture && this.props.card.basePicture[0]) {
        this.setState({
          basePictureURL: this.props.card.basePicture[0].fileSavedURL,
        });
      }
    }
  };
  beforeBaseUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(this.props.t("BASE_FILE_NOT_SUPPORTED"));
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error(this.props.t("BASE_PICTURE_LIMIT"));
    }
    return isJpgOrPng && isLt2M;
  };
  //PANORAMARA PICTURE UPLOAD
  onChangePanorama = async (event) => {
    if (event.file.status === "uploading") {
      this.props.setSpinning(true);
    }
    if (event.file.status === "done") {
      this.props.setSpinning(false);
      await this.props.getCardDataById(
        this.props.cardId,
        this.props.languageId
      );
      if (
        this.props.card.panoramaPicture &&
        this.props.card.panoramaPicture[0]
      ) {
        const fileName =
          this.props.card.panoramaPicture[0].fileSavedURL.split("=");
        const ext = fileName[1].split(".");
        this.setState({
          panoramaPictureURL: this.props.card.panoramaPicture[0].fileSavedURL,
          panoramaExtension: ext[1],
        });
      }
    }
  };
  beforePanoramaUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "video/mp4" ||
      file.type === "video/webm";
    if (!isJpgOrPng) {
      message.error(this.props.t("PANORAMA_FILE_NOT_SUPPORTED"));
    }
    const isLt2MPicture = file.size / 1024 / 1024 < 20;
    const isLt2MVideo = file.size / 1024 / 1024 < 250;
    let isLt = false;
    if (file.type === "image/jpeg" || file.type === "image/png") {
      if (!isLt2MPicture) {
        message.error(this.props.t("PANORAMA_PICTURE_LIMIT"));
      }
    }
    if (file.type === "video/mp4" || file.type === "video/webm") {
      if (!isLt2MVideo) {
        message.error(this.props.t("PANORAMA_VIDEO_LIMIT"));
      }
    }
    if (isLt2MPicture || isLt2MVideo) {
      isLt = true;
    }
    return isJpgOrPng && isLt;
  };
  //TITLE PICTURE UPLOAD
  onChangeTitlePicture = async (event) => {
    if (event.file.status === "uploading") {
      this.props.setSpinning(true);
    }
    if (event.file.status === "done") {
      this.props.setSpinning(false);
      await this.props.getCardDataById(
        this.props.cardId,
        this.props.languageId
      );
      if (this.props.card.titlePicture && this.props.card.titlePicture[0]) {
        this.setState({
          titlePictureURL: this.props.card.titlePicture[0].fileSavedURL,
        });
      }
    }
  };
  beforeTitleUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    console.log(file.type);
    if (!isJpgOrPng) {
      message.error(this.props.t("TITLE_FILE_NOT_SUPPORTED"));
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(this.props.t("TITLE_PICTURE_LIMIT"));
    }
    return isJpgOrPng && isLt2M;
  };

  checkAndSaveButtonSetActive = () => {
    if (
      this.state.title.trim() !== "" &&
      this.state.description.trim() !== ""
    ) {
      this.setState({ saveButtonActive: true });
    } else {
      this.setState({ saveButtonActive: false });
    }
  };
  clickOnSave = async () => {
    this.setState({ saveButtonActive: false });
    const cardData = {
      id: this.props.cardId,
      expoId: this.props.expoId,
      title: this.state.title,
      description: this.state.description,
      languageId: this.props.languageId,
    };
    if (this.state.title.trim() === "") {
      notification["warning"]({
        message: this.props.t("ERROR"),
        description: this.props.t("NOT_EMPTY"),
      });
      this.setState({ saveButtonActive: true });
    } else if (this.state.description.trim() === "") {
      notification["warning"]({
        message: this.props.t("ERROR"),
        description: this.props.t("NOT_EMPTY"),
      });
      this.setState({ saveButtonActive: true });
    } else {
      await this.props.changeCardValues(cardData);
      if (this.props.status === "saved") {
        notification["success"]({
          message: this.props.t("SUCCESS"),
          description: this.props.t("NOT_EMPTY_DESC"),
        });
        this.setState({ saveButtonActive: false });
      } else {
        notification["error"]({
          message: this.props.t("ERROR"),
          description: this.props.t("SAVE_ERROR"),
        });
        this.setState({ saveButtonActive: true });
      }
    }
  };

  render() {
    const uploadPanoramaProps = {
      name: "file",
      action:
        process.env.REACT_APP_API_URL +
        "/card/upload/file-to-card/" +
        this.props.cardId +
        "/" +
        this.props.languageId,
      headers: {
        Authorization: this.props.token,
      },
      showUploadList: false,
    };
    const uploadBasePictureProps = {
      name: "file",
      action:
        process.env.REACT_APP_API_URL +
        "/card/upload/file-to-card-base-picture/" +
        this.props.cardId +
        "/" +
        this.props.languageId,
      headers: {
        Authorization: this.props.token,
      },
      showUploadList: false,
    };
    const uploadTitleProps = {
      name: "file",
      action:
        process.env.REACT_APP_API_URL +
        "/card/upload/file-to-card-title-picture/" +
        this.props.cardId +
        "/" +
        this.props.languageId,
      headers: {
        Authorization: this.props.token,
      },
      showUploadList: false,
    };
    return (
      <React.Fragment>
        {this.props.card && (
          <div>
            <Row gutter={[24, 24]}>
              <Col span={4}>{this.props.t("CARD_NAME_INPUT")}</Col>
              <Col span={20}>
                <Input
                  value={this.state.title}
                  onChange={this.changeTitle}
                ></Input>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={4}>{this.props.t("CARD_DESCRIPTION_INPUT")}</Col>
              <Col span={20}>
                <TextArea
                  onChange={this.changeDescription}
                  value={this.state.description}
                  rows={4}
                  autoSize={{ minRows: 6, maxRows: 6 }}
                />
              </Col>
            </Row>
            <Row gutter={[24, 24]} justify="center" align="middle">
              <Button
                disabled={!this.state.saveButtonActive}
                type="primary"
                onClick={this.clickOnSave}
              >
                {this.props.t("SAVE_BUTTON_TITLE")}
              </Button>
            </Row>
            <Divider orientation="center">
              {this.props.t("CARD_DIVIDER_PICTURE")}
            </Divider>
            <Row gutter={[24, 24]} align="middle" justify="center">
              <Col
                span={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Upload
                  showUploadList={false}
                  {...uploadTitleProps}
                  beforeUpload={this.beforeTitleUpload}
                  onChange={this.onChangeTitlePicture}
                >
                  <Button icon={<UploadOutlined />}>
                    {this.props.t("UPLOAD_TITLE_PICTURE")}
                  </Button>
                  <p style={{ fontSize: "small", fontStyle: "italic" }}>
                    {this.props.t("UPLOAD_CARD_TITLE_PICTURE_SIZE_LIMIT")}
                  </p>
                </Upload>
              </Col>
              <Col
                span={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Upload
                  showUploadList={false}
                  {...uploadBasePictureProps}
                  beforeUpload={this.beforeBaseUpload}
                  onChange={this.onChangeBasePicture}
                >
                  <Button icon={<UploadOutlined />}>
                    {this.props.t("UPLOAD_BASE_PICTURE")}
                  </Button>
                  <p style={{ fontSize: "small", fontStyle: "italic" }}>
                    {this.props.t("UPLOAD_CARD_BASE_PICTURE_SIZE_LIMIT")}
                  </p>
                </Upload>
              </Col>
            </Row>
            <Row gutter={[24, 24]} align="middle" justify="center">
              <Col
                span={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  style={{ maxHeight: "20vh", maxWidth: "100%" }}
                  alt="Hiányzó kép"
                  src={
                    process.env.REACT_APP_API_URL + this.state.titlePictureURL
                  }
                ></img>
              </Col>

              <Col
                span={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  style={{ maxHeight: "20vh", maxWidth: "100%" }}
                  alt="Hiányzó kép"
                  src={
                    process.env.REACT_APP_API_URL + this.state.basePictureURL
                  }
                ></img>
              </Col>
            </Row>
            <Row align="middle" justify="center">
              <Col
                span={24}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Upload
                  showUploadList={false}
                  {...uploadPanoramaProps}
                  beforeUpload={this.beforePanoramaUpload}
                  onChange={this.onChangePanorama}
                >
                  <Button icon={<UploadOutlined />}>
                    {this.props.t("UPLOAD_PANORAMA_PICTURE")}
                  </Button>
                </Upload>
              </Col>
              <Col>
                <p style={{ fontSize: "small", fontStyle: "italic" }}>
                  {this.props.t("UPLOAD_CARD_PANORAMA_PICTURE_SIZE_LIMIT")}
                </p>
              </Col>

              <Col
                span={24}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {this.state.panoramaExtension &&
                  (this.state.panoramaExtension === "mp4" ||
                this.state.panoramaExtension === "webm") ? (
                  <ReactPlayer
                    volume={1}
                    controls
                    url={
                      process.env.REACT_APP_API_URL +
                      this.state.panoramaPictureURL
                    }
                  />
                ) : (
                  // <img
                  //   style={{ maxHeight: "20vh", maxWidth: "100%" }}
                  //   alt="Hiányzó kép"
                  //   src={
                  //     process.env.REACT_APP_API_URL +
                  //     this.state.panoramaPictureURL
                  //   }
                  // ></img>
                  <Pannellum
                    width="100%"
                    height="500px"
                    image={
                      process.env.REACT_APP_API_URL +
                      this.state.panoramaPictureURL
                    }
                    pitch={10}
                    yaw={180}
                    hfov={110}
                    autoLoad
                    showZoomCtrl={false}
                    onLoad={() => {
                      console.log("panorama loaded");
                    }}
                  ></Pannellum>
                )}
              </Col>
            </Row>
          </div>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  expo: state.expoReducer.expo,
  expoId: state.expoReducer.expoId,
  card: state.cardReducer.card,
  status: state.cardReducer.status,
  langauges: state.languageReducer.langauges,
});
const CardData = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedCardData)
);

export default CardData;
