import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import loginReducer from "../reducers/login";
import expoReducer from "../reducers/expo";
import mapReducer from "../reducers/map";
import poiReducer from "../reducers/poi";
import cardReducer from "../reducers/card";
import languageReducer from "../reducers/language";
import unityLanguageReducer from "../reducers/unity";
import spinningReducer from "../reducers/spinning";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "expoReducer",
    "mapReducer",
    "poiReducer",
    "cardReducer",
    "languageReducer",
    "unityLanguageReducer",
    "spinningReducer",
  ],
};

const rootReducer = combineReducers({
  loginReducer,
  expoReducer,
  mapReducer,
  poiReducer,
  cardReducer,
  languageReducer,
  unityLanguageReducer,
  spinningReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
