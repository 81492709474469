import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router";
import HttpService from "../../http";

class ConnectedProtectedRoute extends Component {

  isAuth = () => {
    if (this.props.token && this.props.token !== null) {
      HttpService.setAxiosDefaults();
      HttpService.setTokenHeader(this.props.token);
      return true;
    } else {
      return false;
    }
  };

  hasRight = () => {

    if (this.props.rights.includes(this.props.user.right.id)) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.isAuth() ? (
          this.hasRight() ? (
            <Route path={this.props.tath} render={(props) =>
              React.createElement(this.props.component, {
                ...props
              })
            } />
          ) : (
              <Redirect from="**" to="/missing-right" />
            )
        ) : (
            <Redirect from="**" to="/welcome" />
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
});

const ProtectedRoute = connect(mapStateToProps, null)(ConnectedProtectedRoute);
export default ProtectedRoute;
