export const LOGIN_ACTIONS = {
  TOKEN_LOADING: "TOKEN_LOADING",
  TOKEN_LOADED: "TOKEN_LOADED",
  TOKEN_ERROR: "TOKEN_ERROR",
  LOGOUT: "LOGOUT",
};

export const USER = {
  GET_ALL_USER_LOADING: "GET_ALL_USER_LOADING",
  GET_ALL_USER_LOADED: "GET_ALL_USER_LOADED",
  GET_ALL_USER_ERROR: "GET_ALL_USER_ERROR",
};
export const EXPO = {
  GET_EXPO_DATA_LOADING: "GET_EXPO_DATA_LOADING",
  GET_EXPO_DATA_LOADED: "GET_EXPO_DATA_LOADED",
  GET_EXPO_DATA_ERROR: "GET_EXPO_DATA_ERROR",
  GET_EXPO_MAP_DATA_LOADING: "GET_EXPO_MAP_DATA_LOADING",
  GET_EXPO_MAP_DATA_LOADED: "GET_EXPO_MAP_DATA_LOADED",
  GET_EXPO_MAP_DATA_ERROR: "GET_EXPO_MAP_DATA_ERROR",
  GET_ALL_EXPO_LOADING: "GET_ALL_EXPO_LOADING",
  GET_ALL_EXPO_LOADED: "GET_ALL_EXPO_LOADED",
  GET_ALL_EXPO_ERROR: "GET_ALL_EXPO_ERROR",
  CLONE_EXPO_LOADING: "CLONE_EXPO_LOADING",
  CLONE_EXPO_LOADED: "CLONE_EXPO_LOADED",
  CLONE_EXPO_ERROR: "CLONE_EXPO_ERROR",
  ACTIVATE_EXPO_LOADING: "ACTIVATE_EXPO_LOADING",
  ACTIVATE_EXPO_LOADED: "ACTIVATE_EXPO_LOADED",
  ACTIVATE_EXPO_ERROR: "ACTIVATE_EXPO_ERROR",
  ADD_NEW_BASE_MAP_TO_EXPO_LOADING: "ADD_NEW_BASE_MAP_TO_EXPO_LOADING",
  ADD_NEW_BASE_MAP_TO_EXPO_LOADED: "ADD_NEW_BASE_MAP_TO_EXPO_LOADED",
  ADD_NEW_BASE_MAP_TO_EXPO_ERROR: "ADD_NEW_BASE_MAP_TO_EXPO_ERROR",
  CREATE_REAL_EXPO_LOADING: "CREATE_REAL_EXPO_LOADING",
  CREATE_REAL_EXPO_LOADED: "CREATE_REAL_EXPO_LOADED",
  CREATE_REAL_EXPO_ERROR: "CREATE_REAL_EXPO_ERROR",
  EXPO_DATA_ID: "EXPO_DATA_ID",
};
export const MAP = {
  GET_POIS_BY_MAP_ID_LOADING: "GET_POIS_BY_MAP_ID_LOADING",
  GET_POIS_BY_MAP_ID_LOADED: "GET_POIS_BY_MAP_ID_LOADED",
  GET_POIS_BY_MAP_ID_ERROR: "GET_POIS_BY_MAP_ID_ERROR",
  GET_BASE_PICTURE_BY_MAP_ID_LOADING: "GET_BASE_PICTURE_BY_MAP_ID_LOADING",
  GET_BASE_PICTURE_BY_MAP_ID_LOADED: "GET_BASE_PICTURE_BY_MAP_ID_LOADED",
  GET_BASE_PICTURE_BY_MAP_ID_ERROR: "GET_BASE_PICTURE_BY_MAP_ID_ERROR",
};
export const POI = {
  GET_POI_DATA_LOADING: "GET_POI_DATA_LOADING",
  GET_POI_DATA_LOADED: "GET_POI_DATA_LOADED",
  GET_POI_DATA_ERROR: "GET_POI_DATA_ERROR",
  CREATE_POI_SAVING: "CREATE_POI_SAVING",
  CREATE_POI_SAVED: "CREATE_POI_SAVED",
  CREATE_POI_ERROR: "CREATE_POI_ERROR",
  POI_POSITION_CHANGE_SAVING: "POI_POSITION_CHANGE_SAVING",
  POI_POSITION_CHANGE_SAVED: "POI_POSITION_CHANGE_SAVED",
  POI_POSITION_CHANGE_ERROR: "POI_POSITION_CHANGE_ERROR",
  ADD_MAP_TO_POI_SAVING: "ADD_MAP_TO_POI_CHANGE_SAVING",
  ADD_MAP_TO_POI_SAVED: "ADD_MAP_TO_POI_SAVED",
  ADD_MAP_TO_POI_ERROR: "ADD_MAP_TO_POI_ERROR",
  DELETE_POI_SAVING: "DELETE_POI_SAVING",
  DELETE_POI_SAVED: "DELETE_POI_SAVED",
  DELETE_POI_ERROR: "DELETE_POI_ERROR",
  DELETE_MAP_FROM_POI_SAVING: "DELETE_MAP_FROM_POI_SAVING",
  DELETE_MAP_FROM_POI_SAVED: "DELETE_MAP_FROM_POI_SAVED",
  DELETE_MAP_FROM_POI_ERROR: "DELETE_MAP_FROM_POI_ERROR",
  POI_NAME_CHANGE_SAVED: "POI_NAME_CHANGE_SAVED",
  POI_NAME_CHANGE_SAVING: "POI_NAME_CHANGE_SAVING",
  POI_NAME_CHANGE_ERROR: "POI_NAME_CHANGE_ERROR",
  GET_POI_LANGUAGED_TITLE_LOADING: "GET_POI_LANGUAGED_TITLE_LOADING",
  GET_POI_LANGUAGED_TITLE_LOADED: "GET_POI_LANGUAGED_TITLE_LOADED",
  GET_POI_LANGUAGED_TITLE_ERROR: "GET_POI_LANGUAGED_TITLE_ERROR",
};
export const CARD = {
  GET_CARD_DATA_LOADING: "GET_CARD_DATA_LOADING",
  GET_CARD_DATA_LOADED: "GET_CARD_DATA_LOADED",
  GET_CARD_DATA_ERROR: "GET_CARD_DATA_ERROR",
  CREATE_CARD_SAVING: "CREATE_CARD_SAVING",
  CREATE_CARD_SAVED: "CREATE_CARD_SAVED",
  CREATE_CARD_ERROR: "CREATE_CARD_ERROR",
  EDIT_CARD_SAVING: "EDIT_CARD_SAVING",
  EDIT_CARD_SAVED: "EDIT_CARD_SAVED",
  EDIT_CARD_ERROR: "EDIT_CARD_ERROR",
  DELETE_CARD_SAVING: "DELETE_CARD_SAVING",
  DELETE_CARD_SAVED: "DELETE_CARD_SAVED",
  DELETE_CARD_ERROR: "DELETE_CARD_ERROR",
  UPDATE_CARD_DATA_SAVING: "UPDATE_CARD_DATA_SAVING",
  UPDATE_CARD_DATA_SAVED: "UPDATE_CARD_DATA_SAVED",
  UPDATE_CARD_DATA_ERROR: "UPDATE_CARD_DATA_ERROR",
};

export const LANGUAGE = {
  GET_ALL_LANGUAGE_LOADING: "GET_ALL_LANGUAGE_LOADING",
  GET_ALL_LANGUAGE_LOADED: "GET_ALL_LANGUAGE_LOADED",
  GET_ALL_LANGUAGE_ERROR: "GET_ALL_LANGUAGE_ERROR",
};

export const UNITY_LANGUAGE = {
  GET_ALL_LANGUAGED_VALUES_LOADING: "GET_ALL_LANGUAGED_VALUES_LOADING",
  GET_ALL_LANGUAGED_VALUES_LOADED: "GET_ALL_LANGUAGED_VALUES_LOADED",
  GET_ALL_LANGUAGED_VALUES_ERROR: "GET_ALL_LANGUAGED_VALUES_ERROR",
  CHANGE_LANGUAGED_VALUES_SAVING: "CHANGE_LANGUAGED_VALUES_SAVING",
  CHANGE_LANGUAGED_VALUES_SAVED: "CHANGE_LANGUAGED_VALUES_SAVED",
  CHANGE_LANGUAGED_VALUES_ERROR: "CHANGE_LANGUAGED_VALUES_ERROR",
};
export const SPINNING = {
  TRUE: "TRUE",
  FALSE: "FALSE",
};
