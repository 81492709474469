import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Button,
  Col,
  Row,
  Card,
  message,
  Upload,
  Divider,
  Drawer,
  notification,
  Popconfirm,
} from "antd";
import { getExpoData } from "../../actions/expo";
import { getMapPicture, getPoisByMapId } from "../../actions/map";
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { createPoi, deletePoi, savePoiPosition } from "../../actions/poi";
import logo from "../../static/poi.png";
import logoMap from "../../static/poi_with_map.png";
import PoiDataModal from "./poi-data-modal";
const mapDispatchToProps = (dispatch) => {
  return {
    getExpoData: (data) => dispatch(getExpoData(data)),
    getPoisByMapId: (id) => dispatch(getPoisByMapId(id)),
    createPoi: (mapId) => dispatch(createPoi(mapId)),
    getMapPicture: (mapId) => dispatch(getMapPicture(mapId)),
    savePoiPosition: (poiPositionDTO) =>
      dispatch(savePoiPosition(poiPositionDTO)),
    deletePoi: (poiId) => dispatch(deletePoi(poiId)),
  };
};

class ConnectedPois extends Component {
  state = {
    imageHeight: 0,
    imageWidth: 0,
    selectedPoi: {},
    poiList: [],
    movePoi: false,
    isPoiModalVisible: false,
  };

  componentDidMount = async () => {
    let img = new Image();
    img.src = process.env.REACT_APP_API_URL + this.props.pictureURL;
    img.onload = () =>
      this.setState({
        imageHeight: img.height,
        imageWidth: img.width,
      });
    this.setState({ poiList: this.props.pois });
  };
  selectPoi = (item) => {
    const tempPoiList = this.state.poiList;
    for (let index = 0; index < tempPoiList.length; index++) {
      const element = tempPoiList[index];
      element.selected = false;
    }
    this.setState({ poiList: tempPoiList }, () => {
      item.selected = true;
      this.setState({ selectedPoi: item });
    });
  };
  deselectPoi = (item) => {
    const tempPoiList = this.state.poiList;
    for (let index = 0; index < tempPoiList.length; index++) {
      const element = tempPoiList[index];
      element.selected = false;
    }
    this.setState({ poiList: tempPoiList }, () => {
      this.setState({ selectedPoi: {} });
    });
  };

  createPoi = async () => {
    await this.props.createPoi(this.props.mapId);
    await this.props.getPoisByMapId(this.props.mapId);
  };
  //UPLOAD
  onChange = async (info) => {
    if (info.file.status === "uploading") {
      this.props.setSpinning(true);
    }
    if (info.file.status === "done") {
      this.props.setSpinning(false);
      message.success(`${info.file.name} file uploaded successfully`);

      await this.props.getMapPicture(this.props.mapId);
      let img = new Image();
      img.src = process.env.REACT_APP_API_URL + this.props.pictureURL;
      img.onload = () =>
        this.setState({
          imageHeight: img.height,
          imageWidth: img.width,
        });
      await this.props.getPoisByMapId(this.props.mapId);
      this.setState({ poiList: this.props.pois });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  closeAllOpenedPois = () => {
    this.setState({ selected: false });
  };

  deletePoi = async (item) => {
    await this.props.deletePoi(item.id);
    if (this.props.deleteStatus !== "deleting") {
      if (this.props.deleteStatus === "deleted") {
        notification["success"]({
          message: this.props.t("POI_DELETE_SUCCESS_TITLE"),
          description: this.props.t("POI_DELETE_SUCCESS_DESCRIPZION"),
        });
      } else {
        notification["error"]({
          message: this.props.t("POI_DELETE_FAIL_TITLE"),
          description: this.props.t("POI_DELETE_FAIL_DESCRIPZION"),
        });
      }
    }
    await this.props.getPoisByMapId(this.props.mapId);
    await this.props.refreshOrGet();
  };

  //Drag and drop Poi card
  onMouseDown = (event, item) => {
    event.preventDefault();
    this.setState({ movePoi: true });
    const tempPoiList = this.state.poiList;
    for (let index = 0; index < tempPoiList.length; index++) {
      const element = tempPoiList[index];
      element.selected = false;
    }
    this.setState({ poiList: tempPoiList }, () => {
      this.setState({ selectedPoi: item });
    });
  };
  onMouseUp = async (event, item) => {
    event.preventDefault();
    this.setState({ movePoi: false });
    const x =
      ((event.clientX -
        document.getElementById("myimage").getBoundingClientRect().x) /
        document.getElementById("myimage").getBoundingClientRect().width) *
      this.state.imageWidth;
    const y =
      ((event.clientY -
        document.getElementById("myimage").getBoundingClientRect().y) /
        document.getElementById("myimage").getBoundingClientRect().height) *
      this.state.imageHeight;

    if (this.state.selectedPoi.positionX !== undefined) {
      const sPoi = this.state.selectedPoi;
      sPoi.positionY = y;
      sPoi.positionX = x;

      const poiPozChange = {
        id: this.state.selectedPoi.id,
        positionY: y,
        positionX: x,
      };
      await this.props.savePoiPosition(poiPozChange);
      this.setState({
        selectedPoi: {},
      });
    }
  };
  onMouseMove = async (event) => {
    event.preventDefault();
    if (this.state.movePoi === true) {
      const x =
        ((event.clientX -
          document.getElementById("myimage").getBoundingClientRect().x) /
          document.getElementById("myimage").getBoundingClientRect().width) *
        this.state.imageWidth;
      const y =
        ((event.clientY -
          document.getElementById("myimage").getBoundingClientRect().y) /
          document.getElementById("myimage").getBoundingClientRect().height) *
        this.state.imageHeight;

      if (this.state.selectedPoi.positionX !== undefined) {
        const sPoi = this.state.selectedPoi;
        sPoi.positionY = y;
        sPoi.positionX = x;

        this.setState({
          selectedPoi: sPoi,
        });
      }
    }
  };
  poiShowFromList = (event, item) => {
    event.preventDefault();
    const tempPoiList = this.state.poiList;
    for (let index = 0; index < tempPoiList.length; index++) {
      const element = tempPoiList[index];
      element.selected = false;
    }
    this.setState({ poiList: tempPoiList }, () => {
      this.setState({ selectedPoi: item, isPoiModalVisible: true });
    });
  };
  //POi Adatklap
  showPoiData = () => {
    this.setState({ isPoiModalVisible: true });
  };
  poiModalOk = () => {
    this.setState({ isPoiModalVisible: false });
  };
  poiModalCancel = () => {
    this.setState({ isPoiModalVisible: false });
  };

  renderCard = (item, i) => {
    return (
      <Card
        key={i}
        title={
          item.translates && item.translates[0]
            ? item.translates[0].value
            : this.props.t("CARD_NOT_IN_POI")
        }
        extra={[
          <Button
            icon={<CloseOutlined />}
            onClick={() => this.deselectPoi(item)}
          ></Button>,
        ]}
        style={{ width: 300 }}
      >
        <Divider></Divider>
        <Row gutter={24} align="middle" justify="space-between">
          <Button onClick={this.showPoiData}>
            {this.props.t("POI_EDIT_BUTTON")}
          </Button>
          <Popconfirm
            title={this.props.t("ARE_YOU_SURE")}
            onConfirm={() => this.deletePoi(item)}
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          >
            <Button icon={<DeleteOutlined />}></Button>
          </Popconfirm>
        </Row>
      </Card>
    );
  };

  render() {
    const uploadProps = {
      name: "file",
      action: process.env.REACT_APP_API_URL + "/map/upload-image-to-map",
      headers: {
        Authorization: this.props.token,
      },
      data: {
        id: this.props.mapId,
      },
      showUploadList: false,
    };
    return (
      <React.Fragment>
        <Row gutter={[24, 24]} align="bottom" justify="space-between">
          <Button onClick={this.createPoi}>
            {this.props.t("NEW_POI_BUTTON")}
          </Button>
          <Upload
            {...uploadProps}
            onChange={this.onChange}
            beforeUpload={this.beforeUpload}
          >
            <Button icon={<UploadOutlined />}>
              {this.props.t("POI_UPLOAD_BUTTON")}
            </Button>
          </Upload>
        </Row>
        {this.props.isMainMap && !this.props.isRealExpo ? (
          <div>
            {this.state.poiList &&
              this.state.poiList.map((item, i) => (
                <Col>
                  <Row
                    gutter={[12, 12]}
                    key={item.id}
                    // justify="space-between"
                    align="middle"
                  >
                    <Col>
                      <Button
                        onClick={(event) => this.poiShowFromList(event, item)}
                      >
                        {this.props.t("POI_EDIT_BUTTON")}
                      </Button>
                    </Col>
                    <Col key={item.id}>{item.translates[0].value}</Col>
                  </Row>
                </Col>
              ))}
          </div>
        ) : (
          <Row gutter={[24, 24]} align="middle" justify="center">
            <Col span={24} style={{ height: "70vh" }}>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <div style={{ position: "absolute", height: "100%" }}>
                  <img
                    id="myimage"
                    src={process.env.REACT_APP_API_URL + this.props.pictureURL}
                    style={{
                      height: "100%",
                      maxWidth: "100%",
                      width: "auto",
                    }}
                    alt="Nincs kép"
                    onMouseMove={this.onMouseMove}
                    onMouseDown={this.deselectPoi}
                  ></img>
                  {this.state.poiList &&
                    this.state.poiList.map((item, i) => {
                      return (
                        <div
                          key={item.id}
                          style={{
                            position: "absolute",
                            zIndex:
                              item.selected && item.selected === true
                                ? 101
                                : 100,
                            top:
                              (item.positionY / this.state.imageHeight) * 100 +
                              "%",

                            left:
                              (item.positionX / this.state.imageWidth) * 100 +
                              "%",
                          }}
                        >
                          {item.selected && item.selected === true ? (
                            <Row>
                              {item.map && item.map.id ? (
                                <img
                                  src={logoMap}
                                  alt={"Missing picture " + item.id}
                                  style={{
                                    height: "3em",
                                    position: "relative",
                                    top: "-1.5em",
                                  }}
                                ></img>
                              ) : (
                                <img
                                  src={logo}
                                  alt={"Missing picture " + item.id}
                                  style={{
                                    height: "3em",
                                    position: "relative",
                                    top: "-1.5em",
                                  }}
                                ></img>
                              )}
                              <div key={i}>{this.renderCard(item, i)}</div>
                            </Row>
                          ) : (
                            <div>
                              <Row>
                                <Col>
                                  <div
                                    style={{ cursor: "move" }}
                                    onMouseDown={(event) =>
                                      this.onMouseDown(event, item)
                                    }
                                    onMouseUp={this.onMouseUp}
                                  >
                                    {item.map && item.map.id ? (
                                      <img
                                        src={logoMap}
                                        alt={"Missing picture " + item.id}
                                        style={{
                                          height: "3em",
                                          position: "relative",
                                          top: "-1.5em",
                                        }}
                                      ></img>
                                    ) : (
                                      <img
                                        src={logo}
                                        alt={"Missing picture " + item.id}
                                        style={{
                                          height: "3em",
                                          position: "relative",
                                          top: "-1.5em",
                                        }}
                                      ></img>
                                    )}
                                  </div>
                                </Col>
                                <Col>
                                  <Button
                                    onClick={() => this.selectPoi(item)}
                                    icon={<EditOutlined />}
                                  ></Button>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            </Col>
          </Row>
        )}
        <Drawer
          //style={{ zIndex: 102 }}
          width="50vw"
          // height="100vh"
          title={this.props.t("POI_DRAWER_TITLE")}
          placement="right"
          visible={this.state.isPoiModalVisible}
          onOk={this.poiModalOk}
          onCancel={this.poiModalCancel}
          onClose={this.poiModalCancel}
          destroyOnClose={true}
        >
          <PoiDataModal
            refreshOrGet={() => this.props.refreshOrGet()}
            selectedPoi={this.state.selectedPoi}
          />
        </Drawer>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  expo: state.expoReducer.expo,
  deleteStatus: state.poiReducer.deleteStatus,
  pois: state.mapReducer.pois,
  poi: state.poiReducer.poi,
  pictureURL: state.mapReducer.pictureURL,
});
const Pois = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedPois)
);

export default Pois;
