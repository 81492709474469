import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button, Col, notification, Row, Tree } from "antd";
import {
  addNewBaseMapToExpo,
  getExpoData,
  getExpoMapData,
} from "../../actions/expo";
import { getMapPicture, getPoisByMapId } from "../../actions/map";
import Pois from "../pois/pois";

const mapDispatchToProps = (dispatch) => {
  return {
    getExpoData: (data) => dispatch(getExpoData(data)),
    getExpoMapData: (data) => dispatch(getExpoMapData(data)),
    getPoisByMapId: (id) => dispatch(getPoisByMapId(id)),
    getMapPicture: (mapId) => dispatch(getMapPicture(mapId)),
    addNewBaseMapToExpo: (expoId) => dispatch(addNewBaseMapToExpo(expoId)),
  };
};
const gData = [];

class ConnectedMaps extends Component {
  state = {
    convertedData: [],
    gData,
    selectedMapId: null,
    mainMapIsOK: false,
    expoFirstState: [],
    isMainMap: true,
  };
  componentDidMount = async () => {
    const dsata = {
      id: this.props.expoId,
      languageCode: "hu",
      version: 100,
    };
    await this.props.getExpoMapData(dsata);
    if (this.props.isRealExpo) {
      this.setState({ expoFirstState: this.props.maps[0].children });
    } else if (this.props.maps[0]?.id) {
      await this.props.getPoisByMapId(this.props.maps[0].id);
      this.setState({ expoFirstState: this.props.maps[0].children });
    }

    if (this.props.code === 3002) {
      this.setState({ mainMapIsOK: false });
    } else {
      this.setState({ mainMapIsOK: true });
    }

    if (this.props.maps && this.props.maps[0]) {
      this.setState({
        selectedMapId: this.props.maps[0].id,
        selectedMapPictureURL: this.props.maps[0].mapPicutreURL,
      });
      await this.props.getPoisByMapId(this.props.maps[0].id);
      await this.props.getMapPicture(this.props.maps[0].id);
      this.setState({
        convertedData: this.props.maps,
        selectedMapId: this.props.maps[0].id,
      });
    }
  };
  //KIVÉVE MERT BACKEND VÉGZI A KONVERTÁLÁST
  // convertData = (inputArray, key) => {
  //   // console.log(key);
  //   // console.log(inputArray);

  //   if (Array.isArray(inputArray)) {
  //     alert("AA: " + key);
  //     let poisArray = [];

  //     for (let i = 0; i < inputArray.length; i++) {
  //       const element = inputArray[i];

  //       if (element.pois !== null && element.pois !== undefined) {
  //         const keyTemp = key + "-";
  //         const newObj = {
  //           id: element.id,
  //           mapPicutreURL:
  //             element.files && element.files[0]
  //               ? element.files[0].fileSavedURL
  //               : null,
  //           key: keyTemp + i.toString(),
  //           title: element.name,
  //           children: [],
  //         };

  //         let temp2 = [];

  //         for (let j = 0; j < element.pois.length; j++) {
  //           const actPoi = element.pois[j];
  //           if (
  //             actPoi.map !== undefined &&
  //             actPoi.map !== null &&
  //             actPoi.map.id !== undefined
  //           ) {
  //             const tempKey = key + "-" + i.toString() + "-" + j.toString();
  //             const map = {
  //               id: actPoi.map.id,
  //               key: tempKey,
  //               title: actPoi.map.name,
  //               // mapPicutreURL: actPoi.map.files[0].fileSavedURL,
  //               mapPicutreURL:
  //                 actPoi &&
  //                 actPoi.map &&
  //                 actPoi.map.files &&
  //                 actPoi.map?.files[0]
  //                   ? actPoi.map.files[0].fileSavedURL
  //                   : null,
  //               children: [],
  //             };
  //             temp2 = map;
  //             map.children = this.convertData(actPoi.map, tempKey);
  //           }
  //           if (actPoi.map !== undefined && actPoi.map !== null) {
  //             newObj.children = [...newObj.children, temp2];
  //           }
  //         }
  //         poisArray = [...poisArray, newObj];
  //       }
  //     }
  //     return poisArray;
  //   } else {
  //     alert("BB: " + key);
  //     let poisArray = [];
  //     if (inputArray.pois !== null && inputArray.pois !== undefined) {
  //       const element = inputArray;
  //       if (element.pois !== null && element.pois !== undefined) {
  //         const keyTemp = key + "-";

  //         const newObj = {
  //           id: element.id,
  //           mapPicutreURL:
  //             element.files && element.files[0]
  //               ? element.files[0].fileSavedURL
  //               : null,
  //           key: key,
  //           title: element.name,
  //           children: [],
  //         };
  //         let temp2 = [];

  //         for (let j = 0; j < element.pois.length; j++) {
  //           const actPoi = element.pois[j];
  //           if (
  //             actPoi.map !== undefined &&
  //             actPoi.map !== null &&
  //             actPoi.map.id !== undefined
  //           ) {
  //             const tempKey = key + "-" + j.toString();
  //             const map = {
  //               id: actPoi.map.id,
  //               key: tempKey,
  //               title: actPoi.map.name,
  //               // mapPicutreURL: actPoi.map.files[0].fileSavedURL,
  //               mapPicutreURL:
  //                 actPoi &&
  //                 actPoi.map &&
  //                 actPoi.map.files &&
  //                 actPoi.map?.files[0]
  //                   ? actPoi.map.files[0].fileSavedURL
  //                   : null,
  //               children: [],
  //             };
  //             temp2 = map;
  //             map.children = this.convertData(actPoi.map, tempKey);
  //           }
  //           if (actPoi.map !== undefined && actPoi.map !== null) {
  //             newObj.children = [temp2];
  //           }
  //         }
  //         poisArray = [...poisArray, newObj];
  //       }
  //     }
  //     return poisArray;
  //   }
  // };

  // clearKeys = () => {
  //   for (let index = 0; index < this.state.convertedData.length; index++) {
  //     const element = this.state.convertedData[index];
  //     for (let index = 0; index < element.children.length; index++) {
  //       const elementa = element.children[index];
  //       for (let index = 0; index < elementa.children.length; index++) {
  //         const elementb = elementa.children[index];
  //         if (elementa.id === elementb.id) {
  //           console.log(elementb);
  //           elementa.children =  []
  //           elementa.children = elementb.children;
  //           elementb.children = []
  //           console.log(elementb.key);
  //         }
  //       }
  //     }
  //   }
  // };

  getMaps = async (selectedKeys, e) => {
    this.setState(
      {
        selectedMapId: null,
      },
      () => {
        console.log(e.node);

        this.setState({
          selectedMapId: e.node.id,
          isMainMap: e.node.isMainMap,
          selectedMapPictureURL: e.node.mapPicutreURL,
        });
      }
    );
    await this.props.getPoisByMapId(e.node.id);
    await this.props.getMapPicture(e.node.id);
  };
  refreshOrGet = async () => {
    const dsata = {
      id: this.props.expoId,
      languageCode: "hu",
      version: 100,
    };
    await this.props.getExpoMapData(dsata);

    this.setState({ convertedData: this.props.maps });
  };
  addNewBaseMap = async () => {
    await this.props.addNewBaseMapToExpo(this.props.expoId);
    if (this.props.statusExpo === "loaded") {
      const dsata = {
        id: this.props.expoId,
        languageCode: "hu",
        version: 100,
      };
      await this.props.getExpoMapData(dsata);

      if (this.props.code === 3002) {
        this.setState({ mainMapIsOK: false });
      } else {
        this.setState({ mainMapIsOK: true });
      }

      if (this.props.maps && this.props.maps[0]) {
        this.setState({
          selectedMapId: this.props.maps[0].id,
          isMainMap: this.props.maps[0].isMainMap,
          selectedMapPictureURL: this.props.maps[0].mapPicutreURL,
        });
        await this.props.getPoisByMapId(this.props.maps[0].id);
        await this.props.getMapPicture(this.props.maps[0].id);
        this.setState({
          convertedData: this.props.maps,
          selectedMapId: this.props.maps[0].id,
          isMainMap: this.props.maps[0].isMainMap,
        });
      }
      notification["success"]({
        message: "Sikeres",
        description: "Sikeres térkép hozzáadás",
      });
    } else {
      notification["error"]({
        message: this.props.t("ERROR"),
        description: this.props.t("SAVE_ERROR"),
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.mainMapIsOK ? (
          <div>
            <Row>
              <Col span={4}>
                {this.props.maps && (
                  <Tree
                    className="draggable-tree"
                    defaultExpandAll={true}
                    // defaultExpandedKeys={this.state.expandedKeys}
                    // blockNode
                    treeData={this.state.convertedData}
                    onSelect={this.getMaps}
                    //   treeData={this.state.gData}
                  />
                )}
              </Col>
              <Col span={16}>
                {this.state.selectedMapId !== null &&
                  this.props.status === "loaded" && (
                    <Pois
                      isRealExpo={this.props.isRealExpo}
                      isMainMap={this.state.isMainMap}
                      refreshOrGet={() => this.refreshOrGet()}
                      pois={this.props.pois}
                      mapId={this.state.selectedMapId}
                      // mapPictureURL={this.state.selectedMapPictureURL}
                    ></Pois>
                  )}
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            <Row gutter={[24, 24]} align="middle" justify="center">
              <Col>NINCS HOZZÁRENDELT TÉRKÉP</Col>
            </Row>
            <Row gutter={[24, 24]} align="middle" justify="center">
              <Col>
                <Button type="primary" onClick={this.addNewBaseMap}>
                  MAP HOZZÁADÁSA
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  expo: state.expoReducer.expo,
  code: state.expoReducer.code,
  maps: state.expoReducer.maps,
  pois: state.mapReducer.pois,
  status: state.mapReducer.status,
  statusExpo: state.expoReducer.status,
});
const Maps = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedMaps)
);

export default Maps;
