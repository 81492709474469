import { Button, Col, Mentions, Row, Table } from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  getAllUnityLanguagedValues,
  saveUnityLanguagedValues,
} from "../../actions/unity-language";
const mapDispatchToProps = (dispatch) => {
  return {
    getAllUnityLanguagedValues: () => dispatch(getAllUnityLanguagedValues()),
    saveUnityLanguagedValues: (saveObj) =>
      dispatch(saveUnityLanguagedValues(saveObj)),
  };
};
class ConnectedUnity extends Component {
  state = {
    tableData: [],
    selectedRow: undefined,
    valueEN: "",
    valueHU: "",
  };
  componentDidMount = async () => {
    await this.props.getAllUnityLanguagedValues();
    this.setState({ tableData: this.props.lanugedValues });
  };
  editRow = (row) => {
    this.setState({
      selectedRow: row,
      valueEN: row.valueEN,
      valueHU: row.valueHU,
    });
  };

  changeHU = (value) => {
    const actvalue = value;
    this.setState({ valueHU: actvalue });
  };
  changeEN = (value) => {
    const actvalue = value;
    this.setState({ valueEN: actvalue });
  };
  saveChange = async () => {
    const saveObj = {
      id: this.state.selectedRow.id,
      valueEN: this.state.valueEN,
      valueHU: this.state.valueHU,
    };
    await this.props.saveUnityLanguagedValues(saveObj);
    await this.props.getAllUnityLanguagedValues();
    this.setState({ tableData: this.props.lanugedValues });
  };
  render() {
    const columns = [
      {
        title: this.props.t("TABLE_TITLE_UNITY_ID"),
        dataIndex: "unityId",
        key: "unityId",

        render: (text) => <div>{text}</div>,
      },
      {
        title: "",
        align: "right",
        render: (text, row) =>
          !row.isActive ? (
            <Button onClick={() => this.editRow(row)}>
              {this.props.t("TABLE_TITLE_EDIT_UNITY_BUTTON")}
            </Button>
          ) : null,
      },
    ];
    return (
      <div>
        <Row gutter={24}>
          <Col span={6}>
            <Table
              //   loading={this.state.loadWhenCloning}
              size="middle"
              pagination={{
                position: ["bottomCenter"],
                //   pageSize: this.state.pageSize,
                //   current: this.state.current,
              }}
              onChange={this.handleChangeTable}
              //   onShowSizeChange
              rowKey="id"
              //   rowSelection={rowSelection}
              columns={columns}
              dataSource={this.state.tableData}
              scroll={{ y: "60vh" }}
            ></Table>
          </Col>
          {this.state.selectedRow && (
            <Col span="18">
              <Row gutter={[24, 24]}>
                <Col span="12">
                  <Mentions
                    rows={10}
                    onChange={this.changeHU}
                    style={{ width: "100%" }}
                    value={this.state.valueHU}
                    disabled={false}
                    readOnly={false}
                  ></Mentions>
                </Col>
                <Col span="12">
                  <Mentions
                    rows={10}
                    onChange={this.changeEN}
                    style={{ width: "100%" }}
                    value={this.state.valueEN}
                    disabled={false}
                    readOnly={false}
                  ></Mentions>
                </Col>
              </Row>
              <Row gutter={[24, 24]} justify="center">
                <Button type="primary" onClick={this.saveChange}>
                  {this.props.t("TABLE_TITLE_SAVE_UNITY_BUTTON")}
                </Button>
              </Row>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  lanugedValues: state.unityLanguageReducer.lanugedValues,
});
const Unity = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedUnity)
);

export default Unity;
