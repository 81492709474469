import { CARD } from "../constants/action-types";

const initialState = {
  card: null,
  message: {},
  code: null,
  cardUpdateValues: null,
};

const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case CARD.GET_CARD_DATA_LOADING:
      return Object.assign({}, state, {
        card: {},
        message: {},
        status: "loading",
        code: null,
      });
    case CARD.GET_CARD_DATA_LOADED:
      return Object.assign({}, state, {
        card: action.payload,
        status: "loaded",
        message: {},
        code: null,
      });
    case CARD.GET_CARD_DATA_ERROR:
      return Object.assign({}, state, {
        card: null,
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    case CARD.EDIT_CARD_SAVING:
      return Object.assign({}, state, {
        message: {},
        status: "saving",
        code: null,
      });
    case CARD.EDIT_CARD_SAVED:
      return Object.assign({}, state, {
        status: "saved",
        message: {},
        code: null,
      });
    case CARD.EDIT_CARD_ERROR:
      return Object.assign({}, state, {
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    case CARD.CREATE_CARD_SAVING:
      return Object.assign({}, state, {
        card: null,
        message: {},
        status: "saving",
        code: null,
      });
    case CARD.CREATE_CARD_SAVED:
      return Object.assign({}, state, {
        card: action.payload,
        status: "saved",
        message: {},
        code: null,
      });
    case CARD.CREATE_CARD_ERROR:
      return Object.assign({}, state, {
        card: null,
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    case CARD.UPDATE_CARD_DATA_SAVING:
      return Object.assign({}, state, {
        cardUpdateValues: null,
        message: {},
        status: "saving",
        code: null,
      });
    case CARD.UPDATE_CARD_DATA_SAVED:
      return Object.assign({}, state, {
        cardUpdateValues: action.payload,
        status: "saved",
        message: {},
        code: null,
      });
    case CARD.UPDATE_CARD_DATA_ERROR:
      return Object.assign({}, state, {
        cardUpdateValues: null,
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    default:
      return state;
  }
};

export default cardReducer;
