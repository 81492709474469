import { LANGUAGE } from "../constants/action-types";

const initialState = {
  langauges: [],
  message: {},
  code: null,
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE.GET_ALL_LANGUAGE_LOADING:
      return Object.assign({}, state, {
        langauges: [],
        message: {},
        status: "loading",
        code: null,
      });
    case LANGUAGE.GET_ALL_LANGUAGE_LOADED:
      return Object.assign({}, state, {
        langauges: action.payload,
        status: "loaded",
        message: {},
        code: null,
      });
    case LANGUAGE.GET_ALL_LANGUAGE_ERROR:
      return Object.assign({}, state, {
        langauges: [],
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    default:
      return state;
  }
};

export default languageReducer;
