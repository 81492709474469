import React from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import Main from "./components/skeleton/main";
import ProtectedRoute from "./components/ProtectedRoute/protected-route";
import MissingRight from "./components/skeleton/missing-right";
import Login from "./components/login/login";
import { Suspense } from "react";
import { Spin } from "antd";


function App() {
  return (
    <div>
      <Suspense fallback={<Spin spinning={true} />}>

        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route path="/missing-right" component={MissingRight} />
            <ProtectedRoute path="/one-with-nature" component={Main} rights={[1, 2]} />
            <Redirect from="**" to="/login" />
          </Switch>
        </Router>
      </Suspense>
    </div>

  );
}

export default App;
