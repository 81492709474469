import React, { Component } from "react";
import Body from "./body";
import { Layout, Spin, Row, Col, Button } from "antd";
import { Menu } from "antd";
import { PieChartOutlined, UserOutlined } from "@ant-design/icons";
import "./main.scss";
import { NavLink } from "react-router-dom";
import { logout } from "../../actions/login";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const { Header, Sider, Content } = Layout;

const mapDispatch = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

class ConnectedMain extends Component {
  state = {
    collapsed: false,
    selectedKey: null,
  };

  componentDidMount = () => {};

  handleMenuClick = async () => {
    await this.props.logout();
    this.props.history.push("/welcome");
  };

  render() {
    return (
      <Spin
        spinning={this.props.spinning}
        className="customSpin"
        style={{
          zIndex: 100000000,
        }}
        size="large"
      >
        <div style={{ height: "100%" }}>
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{ padding: "0px", height: "100%" }}
            >
              <Row align="middle" justify="center">
                <Col
                  span={4}
                  style={{
                    alignItems: "center",
                    justifyContent: "flex-start",
                    display: "flex",
                  }}
                ></Col>
                <Col
                  span={20}
                  style={{
                    alignItems: "center",
                    justifyContent: "flex-end",
                    display: "flex",
                  }}
                >
                  <div style={{ marginRight: "1em" }}>
                    <p>
                      {this.props.t("MENU_WELCOME")}{" "}
                      <strong>
                        {this.props.user.firstName +
                          " " +
                          this.props.user.lastName +
                          " "}
                      </strong>
                      <Button
                        style={{ marginLeft: "1em" }}
                        icon={<UserOutlined />}
                      ></Button>
                    </p>
                  </div>
                </Col>
              </Row>
            </Header>
          </Layout>
          <Layout style={{ height: "93vh" }}>
            <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
              <Menu
                style={{ height: "85vh" }}
                theme="dark"
                mode="inline"
                selectedKeys={[this.state.selectedKey]}
                onSelect={this.handleSelect}
              >
                <Menu.Item key="1" icon={<PieChartOutlined />}>
                  <NavLink
                    to="/one-with-nature/real-expo"
                    className="link-color"
                  >
                    {this.props.t("REAL_EXPO")}
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="2" icon={<PieChartOutlined />}>
                  <NavLink
                    to="/one-with-nature/digital-expo"
                    className="link-color"
                  >
                    {this.props.t("DIGITAL_EXPO")}
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="3" icon={<PieChartOutlined />}>
                  <NavLink to="/one-with-nature/unity" className="link-color">
                    {this.props.t("UNITY")}
                  </NavLink>
                </Menu.Item>
              </Menu>
              <Row justify="center" align="middle">
                <Button onClick={this.handleMenuClick}>
                  {this.props.t("MENU_LOGOUT")}
                </Button>
              </Row>
            </Sider>
            <Layout className="site-layout">
              <Content
                className="site-layout-background"
                style={{
                  margin: "24px 16px",
                  padding: 24,
                  height: "100%",
                  //minHeight: 500,
                }}
              >
                <Body />
              </Content>
            </Layout>
          </Layout>
        </div>
      </Spin>
    );
  }
}

const mapState = (state) => {
  return {
    user: state.loginReducer.user,
    spinning: state.spinningReducer.spinning,
  };
};

const Main = withTranslation()(connect(mapState, mapDispatch)(ConnectedMain));
export default Main;
