import {
  DeleteOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Input,
  message,
  notification,
  Popconfirm,
  Row,
  Upload,
} from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  addMapToPoi,
  deleteMapFromPoi,
  getPoiDataById,
  getPoiNameByPoiIdAndLanguageId,
  savePoiChangeName,
} from "../../actions/poi";
import CardData from "../card/card";
import { Select } from "antd";
import { createCard, deleteCard } from "../../actions/card";

const { Option } = Select;

const mapDispatchToProps = (dispatch) => {
  return {
    getPoiDataById: (poiId) => dispatch(getPoiDataById(poiId)),
    createCard: (poiId) => dispatch(createCard(poiId)),
    deleteCard: (cardId) => dispatch(deleteCard(cardId)),
    addMapToPoi: (poiId) => dispatch(addMapToPoi(poiId)),
    savePoiChangeName: (poi) => dispatch(savePoiChangeName(poi)),
    deleteMapFromPoi: (poiId) => dispatch(deleteMapFromPoi(poiId)),
    getPoiNameByPoiIdAndLanguageId: (poiId, languageId) =>
      dispatch(getPoiNameByPoiIdAndLanguageId(poiId, languageId)),
  };
};

class ConnectedPoiDataModal extends Component {
  state = {
    poiData: undefined,
    drawerOpen: false,
    cardId: null,
    selectedLanguageId: 1,
    name: "",
    pictureURL: "",
  };
  componentDidMount = async () => {
    await this.props.getPoiDataById(this.props.selectedPoi.id);
    const loadedObj = this.props.loadedPoi;
    if (!this.props.langauges) {
      await this.props.getAllLanguage();
    }
    await this.props.getPoiNameByPoiIdAndLanguageId(
      this.props.selectedPoi.id,
      this.state.selectedLanguageId
    );
    this.setState({
      name: this.props.title,
      pictureURL: this.props.loadedPoi.basePictures[0]
        ? this.props.loadedPoi.basePictures[0].fileSavedURL
        : "",
    });
    this.setState({ poiData: loadedObj });
  };
  closeDrawer = () => {
    this.setState({ drawerOpen: false, cardId: null });
  };
  openDrawer = (item) => {
    this.setState({ drawerOpen: true, cardId: item.id });
  };

  addNewCardToPoi = async () => {
    await this.props.createCard(this.state.poiData.id);
    await this.props.getPoiDataById(this.props.selectedPoi.id);
    const loadedObj = this.props.loadedPoi;
    this.setState({ poiData: loadedObj });
  };

  handleChangeLanguage = async (event) => {
    this.setState({ selectedLanguageId: event }, async () => {
      await this.props.getPoiNameByPoiIdAndLanguageId(
        this.props.selectedPoi.id,
        this.state.selectedLanguageId
      );
      this.setState({ name: this.props.title });
    });
  };
  deleteCard = async (item) => {
    await this.props.deleteCard(item.id);
    await this.props.getPoiDataById(this.props.selectedPoi.id);
    const loadedObj = this.props.loadedPoi;
    this.setState({ poiData: loadedObj });
  };

  addMapToPoi = async () => {
    await this.props.addMapToPoi(this.props.selectedPoi.id);
    await this.props.refreshOrGet();
  };
  changePoiName = (event) => {
    const value = event.target.value;
    console.log(event.target.value);
    this.setState({ name: value });
  };
  savePoiNameChange = async () => {
    const obj = {
      id: this.props.selectedPoi.id,
      name: this.state.name,
      languageId: this.state.selectedLanguageId,
    };
    await this.props.savePoiChangeName(obj);
  };
  deleteMap = async () => {
    await this.props.deleteMapFromPoi(this.props.selectedPoi.id);
    if (this.props.deleteStatus === "deleted") {
      notification["success"]({
        message: "Siekeres",
        description: "A törlés siekrült",
      });
      await this.props.refreshOrGet();
    } else {
      notification["error"]({
        message: "Hiba",
        description: "A törlés sikertelen",
      });
    }
  };
  addPicture = () => {};
  beforeUploadPoiPicture = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error("Image must smaller than 1MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  onChangePoiPicture = async (event) => {
    if (event.file.status === "done") {
      await this.props.getPoiDataById(this.props.selectedPoi.id);
      const loadedObj = this.props.loadedPoi;
      this.setState({
        poiData: loadedObj,
        pictureURL: loadedObj.basePictures[0]
          ? loadedObj.basePictures[0].fileSavedURL
          : "",
      });
      console.log(loadedObj);
      // if (this.props.card.titlePicture && this.props.card.titlePicture[0]) {
      //   this.setState({
      //     titlePictureURL: this.props.card.titlePicture[0].fileSavedURL,
      //   });
      // }
    }
  };
  //card render
  cardRender = (item, i) => {
    return (
      <div key={i}>
        <Row gutter={[24, 24]} align="middle" justify="space-between">
          <Col span={20}>
            <Row>{item.name}</Row>
          </Col>
          <Col span={4}>
            <Row align="middle" justify="space-around">
              <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  this.deleteCard(item);
                }}
              ></Button>
              <Button
                icon={<EyeOutlined />}
                onClick={() => this.openDrawer(item)}
              ></Button>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };
  render() {
    const uploadProps = {
      name: "file",
      action:
        process.env.REACT_APP_API_URL +
        "/poi/upload/file-to-poi/" +
        this.props.selectedPoi.id +
        "/" +
        1, //DEFAULT language ID
      headers: {
        Authorization: this.props.token,
      },
      showUploadList: false,
    };
    return (
      <React.Fragment>
        <Divider orientation="center">Nyelv választó</Divider>
        <Row gutter={[24, 24]} align="middle" justify="space-between">
          <Col>
            <Select
              defaultValue={1}
              style={{ width: 120 }}
              onChange={this.handleChangeLanguage}
            >
              {this.props.langauges &&
                this.props.langauges.map((langauge) => {
                  return (
                    <Option value={langauge.id} key={langauge.id}>
                      {langauge.name}
                    </Option>
                  );
                })}
            </Select>
          </Col>
        </Row>
        <Divider orientation="center">Név</Divider>
        {this.state.poiData && this.state.poiData.id && (
          <Row gutter={[24, 24]} align="middle" justify="space-between">
            <Col span={20}>
              <Input
                value={this.state.name}
                onChange={this.changePoiName}
              ></Input>
            </Col>
            <Col>
              <Button type="primary" onClick={this.savePoiNameChange}>
                {this.props.t("POI_NAME_CHANE_BUTTON")}
              </Button>
            </Col>
          </Row>
        )}
        <Divider orientation="center">KÉP</Divider>
        <Row gutter={24} align="middle" justify="space-between">
          {/* <Col span={24}></Col> */}
          <Col>
            <Upload
              showUploadList={false}
              {...uploadProps}
              beforeUpload={this.beforeUploadPoiPicture}
              onChange={this.onChangePoiPicture}
            >
              <Button icon={<UploadOutlined />}>
                {this.props.t("UPLOAD_POI_PICTURE")}
              </Button>
              <p style={{ fontSize: "small", fontStyle: "italic" }}>
                {this.props.t("UPLOAD_POI_PICTURE_SIZE_LIMIT")}
              </p>
            </Upload>
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <img
              style={{ maxHeight: "10vh", maxWidth: "10vw" }}
              alt="Hiányzó kép"
              src={process.env.REACT_APP_API_URL + this.state.pictureURL}
            ></img>
          </Col>
        </Row>
        <Divider orientation="center">Kártyák</Divider>
        <Row gutter={[24, 24]} justify="center">
          {this.state.poiData &&
          this.state.poiData.cards &&
          this.state.poiData.cards[0] ? null : (
            <Col>
              <Button onClick={this.addNewCardToPoi}>
                {this.props.t("POI_ADD_NEW_CARD")}
              </Button>
            </Col>
          )}
        </Row>
        {this.state.poiData &&
          this.state.poiData.cards.map((item, i) => this.cardRender(item, i))}
        <Divider orientation="center">
          <div> {this.props.t("POI_ADD_NEW_MAP")}</div>
        </Divider>
        <Row gutter={[24, 24]} align="middle" justify="center">
          {this.state.poiData &&
          this.state.poiData.map &&
          this.state.poiData.map.id ? (
            <div>
              <Col>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => this.deleteMap()}
                  icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                >
                  <Button icon={<DeleteOutlined />}>
                    {this.props.t("POI_DELETE_MAP_BUTTON")}
                  </Button>
                </Popconfirm>
              </Col>
            </div>
          ) : (
            <div>
              <Col>
                <Button onClick={this.addMapToPoi}>
                  {this.props.t("POI_ADD_NEW_MAP_BUTTON")}
                </Button>
              </Col>
            </div>
          )}
        </Row>

        <Drawer
          title={this.props.t("CARD_DRAWER_TITLE")}
          width="50vw"
          onClose={this.closeDrawer}
          visible={this.state.drawerOpen}
          destroyOnClose={true}
        >
          <CardData
            cardId={this.state.cardId}
            languageId={this.state.selectedLanguageId}
          />
        </Drawer>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  token:  state.loginReducer.token,
  title: state.poiReducer.title,
  loadedPoi: state.poiReducer.loadedPoi,
  deleteStatus: state.poiReducer.deleteStatus,
  langauges: state.languageReducer.langauges,
});
const PoiDataModal = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedPoiDataModal)
);

export default PoiDataModal;
