import { MAP } from "../constants/action-types";

const initialState = {
  pois: null,
  pictureURL: null,
  message: {},
  code: null,
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAP.GET_POIS_BY_MAP_ID_LOADING:
      return Object.assign({}, state, {
        pois: {},
        message: {},
        status: "loading",
        code: null,
      });
    case MAP.GET_POIS_BY_MAP_ID_LOADED:
      return Object.assign({}, state, {
        pois: action.payload,
        status: "loaded",
        message: {},
        code: null,
      });
    case MAP.GET_POIS_BY_MAP_ID_ERROR:
      return Object.assign({}, state, {
        pois: null,
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    case MAP.GET_BASE_PICTURE_BY_MAP_ID_LOADING:
      return Object.assign({}, state, {
        pictureURL: null,
        message: {},
        status: "loading",
        code: null,
      });
    case MAP.GET_BASE_PICTURE_BY_MAP_ID_LOADED:
      return Object.assign({}, state, {
        pictureURL: action.payload,
        status: "loaded",
        message: {},
        code: null,
      });
    case MAP.GET_BASE_PICTURE_BY_MAP_ID_ERROR:
      return Object.assign({}, state, {
        pictureURL: null,
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    default:
      return state;
  }
};

export default mapReducer;
