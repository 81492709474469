import { EXPO } from "../constants/action-types";

const initialState = {
  expo: null,
  maps : [],
  expos: [],
  message: {},
  code: null,
  cloneSuccess: false,
  activateSuccess: false,
  expoId: null
};

const expoReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXPO.GET_EXPO_DATA_LOADING:
      return Object.assign({}, state, {
        expo: {},
        message: {},
        status: "loading",
        code: null,
      });
    case EXPO.GET_EXPO_DATA_LOADED:
      return Object.assign({}, state, {
        expo: action.payload,
        status: "loaded",
        message: {},
        code: null,
      });
    case EXPO.GET_EXPO_DATA_ERROR:
      return Object.assign({}, state, {
        expo: null,
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    case EXPO.GET_ALL_EXPO_LOADING:
      return Object.assign({}, state, {
        expos: [],
        message: {},
        status: "loading",
        code: null,
      });
    case EXPO.GET_ALL_EXPO_LOADED:
      return Object.assign({}, state, {
        expos: action.payload,
        status: "loaded",
        message: {},
        code: null,
      });
    case EXPO.GET_ALL_EXPO_ERROR:
      return Object.assign({}, state, {
        expos: [],
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    case EXPO.CLONE_EXPO_LOADING:
      return Object.assign({}, state, {
        cloneSuccess: false,
        message: {},
        status: "loading",
        code: null,
      });
    case EXPO.CLONE_EXPO_LOADED:
      return Object.assign({}, state, {
        cloneSuccess: true,
        status: "loaded",
        message: {},
        code: null,
      });
    case EXPO.CLONE_EXPO_ERROR:
      return Object.assign({}, state, {
        cloneSuccess: false,
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    case EXPO.ACTIVATE_EXPO_LOADING:
      return Object.assign({}, state, {
        activateSuccess: false,
        message: {},
        status: "loading",
        code: null,
      });
    case EXPO.ACTIVATE_EXPO_LOADED:
      return Object.assign({}, state, {
        activateSuccess: true,
        status: "loaded",
        message: {},
        code: null,
      });
    case EXPO.ACTIVATE_EXPO_ERROR:
      return Object.assign({}, state, {
        activateSuccess: false,
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    case EXPO.GET_EXPO_MAP_DATA_LOADING:
      return Object.assign({}, state, {
        maps: [],
        message: {},
        status: "loading",
        code: null,
      });
    case EXPO.GET_EXPO_MAP_DATA_LOADED:
      return Object.assign({}, state, {
        maps: action.payload,
        status: "loaded",
        message: {},
        code: null,
      });
    case EXPO.GET_EXPO_MAP_DATA_ERROR:
      return Object.assign({}, state, {
        maps: [],
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    case EXPO.ADD_NEW_BASE_MAP_TO_EXPO_LOADING:
      return Object.assign({}, state, {
        message: {},
        status: "loading",
        code: null,
      });
    case EXPO.ADD_NEW_BASE_MAP_TO_EXPO_LOADED:
      return Object.assign({}, state, {
        status: "loaded",
        message: {},
        code: null,
      });
    case EXPO.ADD_NEW_BASE_MAP_TO_EXPO_ERROR:
      return Object.assign({}, state, {
        status: "error",
        message: action.payload.message,
        code: action.payload.code,
      });
    case EXPO.EXPO_DATA_ID:
      return Object.assign({}, state, {
        expoId: action.payload,
      });
    default:
      return state;
  }
};

export default expoReducer;
