import { SPINNING } from "../constants/action-types";

export const setSpinning = (status) => {
  return async (dispatch) => {
    if (status === true) {
      dispatch({ type: SPINNING.TRUE });
    } else {
      dispatch({ type: SPINNING.FALSE });
    }
  };
};
